<template>
  <v-divider class="secondary mt-5 mb-5" />
</template>

<script>
export default {
  name: "taDividerOne",
};
</script>

<style scoped></style>
