import * as API from "@/services/API";

export default {
  loginA(payload) {
    return API.apiClient.post("/loginAscan", payload);
  },
  loginB(payload) {
    return API.apiClient.post("/loginBscan", payload);
  },
  loginC(payload) {
    return API.apiClient.post("/loginCscan", payload);
  },
  loginD(payload) {
    return API.apiClient.post("/loginDscan", payload);
  },
  loginE(payload) {
    return API.apiClient.post("/loginEscan", payload);
  },
  loginH(payload) {
    return API.apiClient.post("/loginHscan", payload);
  },
  loginI(payload) {
    return API.apiClient.post("/loginIscan", payload);
  },
  loginJ(payload) {
    return API.apiClient.post("/loginJscan", payload);
  },
  loginK(payload) {
    return API.apiClient.post("/loginKscan", payload);
  },
  loginL(payload) {
    return API.apiClient.post("/loginLscan", payload);
  },
  loginM(payload) {
    return API.apiClient.post("/loginMscan", payload);
  },
  loginN(payload) {
    return API.apiClient.post("/loginNscan", payload);
  },
  loginO(payload) {
    return API.apiClient.post("/loginOscan", payload);
  },
  loginP(payload) {
    return API.apiClient.post("/loginPscan", payload);
  },
  loginQ(payload) {
    return API.apiClient.post("/loginQscan", payload);
  },
  loginR(payload) {
    return API.apiClient.post("/loginRscan", payload);
  },
  loginT(payload) {
    return API.apiClient.post("/loginTscan", payload);
  },
  loginU(payload) {
    return API.apiClient.post("/loginUscan", payload);
  },
  loginW(payload) {
    return API.apiClient.post("/loginWscan", payload);
  },
  loginAB(payload) {
    return API.apiClient.post("/loginABscan", payload);
  },

  logout(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutCscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutA(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutAscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutB(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutBscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutC(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutCscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutD(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutDscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutE(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutEscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutH(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutHscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutI(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutIscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutJ(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutJscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutK(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutKscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutL(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutLscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutM(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutMscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutN(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutNscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutO(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutOscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutP(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutPscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutQ(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutQscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutR(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutRscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutT(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutTscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutU(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutUscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutW(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutWscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  logoutAB(payload) {
    const token = localStorage.getItem("token");
    return API.apiClient.post("/logoutABscan", payload, {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  notIntA(payload) {
    return API.apiClient.post("/notIntAscan", payload);
  },
  notIntB(payload) {
    return API.apiClient.post("/notIntBscan", payload);
  },
  notIntC(payload) {
    return API.apiClient.post("/notIntCscan", payload);
  },
  notIntD(payload) {
    return API.apiClient.post("/notIntDscan", payload);
  },
  notIntE(payload) {
    return API.apiClient.post("/notIntEscan", payload);
  },
  notIntH(payload) {
    return API.apiClient.post("/notIntHscan", payload);
  },
  notIntI(payload) {
    return API.apiClient.post("/notIntIscan", payload);
  },
  notIntJ(payload) {
    return API.apiClient.post("/notIntJscan", payload);
  },
  notIntK(payload) {
    return API.apiClient.post("/notIntKscan", payload);
  },
  notIntL(payload) {
    return API.apiClient.post("/notIntLscan", payload);
  },
  notIntM(payload) {
    return API.apiClient.post("/notIntMscan", payload);
  },
  notIntN(payload) {
    return API.apiClient.post("/notIntNscan", payload);
  },
  notIntO(payload) {
    return API.apiClient.post("/notIntOscan", payload);
  },
  notIntP(payload) {
    return API.apiClient.post("/notIntPscan", payload);
  },
  notIntQ(payload) {
    return API.apiClient.post("/notIntQscan", payload);
  },
  notIntR(payload) {
    return API.apiClient.post("/notIntRscan", payload);
  },
  notIntT(payload) {
    return API.apiClient.post("/notIntTscan", payload);
  },
  notIntU(payload) {
    return API.apiClient.post("/notIntUscan", payload);
  },
  notIntW(payload) {
    return API.apiClient.post("/notIntWscan", payload);
  },
  notIntAB(payload) {
    return API.apiClient.post("/notIntABscan", payload);
  },
};
